import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { GetUrl, PostUrl } from "../BaseUrl";

export const handleGetCategories = createAsyncThunk(
  "categoryandtag/handleGetCategories",
  async ({ token, lang }, { rejectWithValue }) => {
    try {
      const { data } = await GetUrl("category", {
        headers: { Authorization: token, "Accept-Language": lang },
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const handleGetCategoryById = createAsyncThunk(
  "categoryandtag/handleGetCategoryById",
  async ({ token, lang, id }, { rejectWithValue }) => {
    try {
      const { data } = await GetUrl(`category/${id}`, {
        headers: { Authorization: token, "Accept-Language": lang },
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const handleAddCategory = createAsyncThunk(
  "categoryandtag/handleAddCategory",
  async (
    { token, lang, frname, enname, image, website, signal },
    { rejectWithValue }
  ) => {
    signal.current = new AbortController();
    const formData = new FormData();
    formData.append("en[name]", enname);
    formData.append("fr[name]", frname);
    formData.append("image", image);
    formData.append("website", website);
    try {
      const { data } = await PostUrl("category", {
        data: formData,
        headers: {
          Authorization: token,
          "Accept-Language": lang,
          "Content-Type": "multipart/form-data",
        },
        signal: signal.current.signal,
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const handleEditCategory = createAsyncThunk(
  "categoryandtag/handleEditCategory",
  async (
    {
      token,
      lang,
      showOnNavbar,
      showOnHomePage,
      frname,
      enname,
      id,
      image,
      website,
      signal,
    },
    { rejectWithValue }
  ) => {
    signal.current = new AbortController();
    const formData = new FormData();
    formData.append("en[name]", enname);
    formData.append("fr[name]", frname);
    formData.append("image", image);
    formData.append("website", website);
    formData.append("showOnNavbar", showOnNavbar);
    formData.append("showOnHomePage", showOnHomePage);
    try {
      const { data } = await PostUrl(`category/${id}`, {
        data: formData,
        headers: {
          Authorization: token,
          "Accept-Language": lang,
          "Content-Type": "multipart/form-data",
        },
        signal: signal.current.signal,
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const handleDeleteCategory = createAsyncThunk(
  "categoryandtag/handleDeleteCategory",
  async ({ token, lang, id, signal }, { rejectWithValue }) => {
    signal.current = new AbortController();
    try {
      const { data } = await GetUrl(`category/delete/${id}`, {
        headers: {
          Authorization: token,
          "Accept-Language": lang,
          "Content-Type": "multipart/form-data",
        },
        signal: signal.current.signal,
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const handleGetSubCategories = createAsyncThunk(
  "categoryandtag/handleGetTags",
  async ({ token, lang }, { rejectWithValue }) => {
    try {
      const { data } = await GetUrl("sub-categories", {
        headers: { Authorization: token, "Accept-Language": lang },
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const handleGetSubCategoryById = createAsyncThunk(
  "categoryandtag/handleGetTagById",
  async ({ token, lang, id }, { rejectWithValue }) => {
    try {
      const { data } = await GetUrl(`sub-categories/${id}`, {
        headers: { Authorization: token, "Accept-Language": lang },
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const handleAddSubCategory = createAsyncThunk(
  "categoryandtag/handleAddTag",
  async ({ token, lang, name, category, signal }, { rejectWithValue }) => {
    signal.current = new AbortController();
    try {
      const { data } = await PostUrl("sub-categories", {
        data: { name, category },
        headers: { Authorization: token, "Accept-Language": lang },
        signal: signal.current.signal,
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const handleEditSubCategory = createAsyncThunk(
  "categoryandtag/handleEditTag",
  async ({ token, lang, id, name, category, signal }, { rejectWithValue }) => {
    signal.current = new AbortController();
    try {
      const { data } = await PostUrl(`sub-categories/${id}`, {
        data: { name, category },
        headers: { Authorization: token, "Accept-Language": lang },
        signal: signal.current.signal,
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const handleDeleteSubCategory = createAsyncThunk(
  "categoryandtag/handleDeleteTag",
  async ({ token, id, signal }, { rejectWithValue }) => {
    signal.current = new AbortController();
    try {
      const { data } = await GetUrl(`sub-categories/delete/${id}`, {
        headers: { Authorization: token },
        signal: signal.current.signal,
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  categoryLoading: false,
  categories: [],
  categoryAddAndEditLoading: false,
  categoryRemoveLoading: false,
  singleCategory: null,
  subCategoryLoading: false,
  subCategoryAddAndEditLoading: false,
  subCategoryRemoveLoading: false,
  singleSubCategory: null,
  subCategories: [],
  error: null,
};

const CategoryAndTagsSlice = createSlice({
  name: "categoryandtag",
  initialState,
  reducers: {
    handleChangeSingleCategory: (state, { payload }) => {
      state.singleCategory = payload;
    },
    handleChangeSingleSubCategory: (state, { payload }) => {
      state.singleSubCategory = payload;
    },
  },
  extraReducers: (buidler) => {
    // get all categories
    buidler
      .addCase(handleGetCategories.pending, (state) => {
        state.categoryLoading = true;
      })
      .addCase(handleGetCategories.fulfilled, (state, { payload }) => {
        state.categoryLoading = false;
        state.categories = payload?.categories;
        state.error = null;
      })
      .addCase(handleGetCategories.rejected, (state, { payload }) => {
        state.categoryLoading = false;
        state.categories = [];
        state.error = payload ?? null;
      });

    // get by id category
    buidler
      .addCase(handleGetCategoryById.pending, (state) => {
        state.categoryLoading = true;
      })
      .addCase(handleGetCategoryById.fulfilled, (state, { payload }) => {
        state.categoryLoading = false;
        state.singleCategory = payload?.category;
        state.error = null;
      })
      .addCase(handleGetCategoryById.rejected, (state, { payload }) => {
        state.categoryLoading = false;
        state.singleCategory = null;
        state.error = payload ?? null;
      });

    // add category
    buidler
      .addCase(handleAddCategory.pending, (state) => {
        state.categoryAddAndEditLoading = true;
      })
      .addCase(handleAddCategory.fulfilled, (state, { payload }) => {
        state.categoryAddAndEditLoading = false;
        state.categories = [payload?.category, ...state?.categories];
        state.error = null;
      })
      .addCase(handleAddCategory.rejected, (state, { payload }) => {
        state.categoryAddAndEditLoading = false;
        state.error = payload ?? null;
      });

    // edit category
    buidler
      .addCase(handleEditCategory.pending, (state) => {
        state.categoryAddAndEditLoading = true;
      })
      .addCase(handleEditCategory.fulfilled, (state, { payload, meta }) => {
        state.categoryAddAndEditLoading = false;
        state.categories = state.categories.map((category) => {
          if (category?._id === meta.arg.id) {
            return payload?.category;
          } else {
            return category;
          }
        });
        state.error = null;
      })
      .addCase(handleEditCategory.rejected, (state, { payload }) => {
        state.categoryAddAndEditLoading = false;
        state.error = payload ?? null;
      });

    // delete category
    buidler
      .addCase(handleDeleteCategory.pending, (state) => {
        state.categoryRemoveLoading = true;
      })
      .addCase(handleDeleteCategory.fulfilled, (state, { payload, meta }) => {
        state.categoryRemoveLoading = false;
        state.categories = state.categories.filter((category) => {
          return category?._id !== meta.arg.id;
        });
        state.error = null;
      })
      .addCase(handleDeleteCategory.rejected, (state, { payload }) => {
        state.categoryRemoveLoading = false;
        state.error = payload ?? null;
      });

    // get all sub categories
    buidler
      .addCase(handleGetSubCategories.pending, (state) => {
        state.subCategoryLoading = true;
      })
      .addCase(handleGetSubCategories.fulfilled, (state, { payload }) => {
        state.subCategoryLoading = false;
        state.subCategories = payload?.subCategories;
        state.error = null;
      })
      .addCase(handleGetSubCategories.rejected, (state, { payload }) => {
        state.subCategoryLoading = false;
        state.subCategories = [];
        state.error = payload ?? null;
      });

    // get by id sub category
    buidler
      .addCase(handleGetSubCategoryById.pending, (state) => {
        state.subCategoryLoading = true;
      })
      .addCase(handleGetSubCategoryById.fulfilled, (state, { payload }) => {
        state.subCategoryLoading = false;
        state.singleSubCategory = payload?.subCategory;
        state.error = null;
      })
      .addCase(handleGetSubCategoryById.rejected, (state, { payload }) => {
        state.subCategoryLoading = false;
        state.singelTag = null;
        state.error = payload ?? null;
      });

    // add sub category
    buidler
      .addCase(handleAddSubCategory.pending, (state) => {
        state.subCategoryAddAndEditLoading = true;
      })
      .addCase(handleAddSubCategory.fulfilled, (state, { payload }) => {
        state.subCategoryAddAndEditLoading = false;
        state.subCategories = [payload?.subCategory, ...state.subCategories];
        state.error = null;
      })
      .addCase(handleAddSubCategory.rejected, (state, { payload }) => {
        state.subCategoryAddAndEditLoading = false;
        state.error = payload ?? null;
      });

    // edit sub category
    buidler
      .addCase(handleEditSubCategory.pending, (state) => {
        state.subCategoryAddAndEditLoading = true;
      })
      .addCase(handleEditSubCategory.fulfilled, (state, { payload, meta }) => {
        state.subCategoryAddAndEditLoading = false;
        state.subCategories = state.subCategories.map((subCategory) => {
          if (subCategory?._id === meta.arg.id) {
            return payload?.subCategory;
          } else {
            return subCategory;
          }
        });
        state.error = null;
      })
      .addCase(handleEditSubCategory.rejected, (state, { payload }) => {
        state.subCategoryAddAndEditLoading = false;
        state.error = payload ?? null;
      });

    // delete sub category
    buidler
      .addCase(handleDeleteSubCategory.pending, (state) => {
        state.subCategoryRemoveLoading = true;
      })
      .addCase(handleDeleteSubCategory.fulfilled, (state, { payload, meta }) => {
        state.subCategoryRemoveLoading = false;
        state.subCategories = state.subCategories.filter((subCategory) => {
          return subCategory?._id !== meta.arg.id;
        });
        state.error = null;
      })
      .addCase(handleDeleteSubCategory.rejected, (state, { payload }) => {
        state.subCategoryRemoveLoading = false;
        state.error = payload ?? null;
      });
  },
});

export const { handleChangeSingleCategory, handleChangeSingleSubCategory } =
  CategoryAndTagsSlice.actions;

export default CategoryAndTagsSlice.reducer;
