// src/features/TagSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { GetUrl, PostUrl } from "../BaseUrl";

/* 
  ========= Thunks =========
  1) handleGetTags
  2) handleGetTagById
  3) handleAddTag
  4) handleEditTag
  5) handleDeleteTag
*/

/**
 * 1) Récupérer tous les tags
 *    GET /tag?page=...&limit=...&query=...&website=...
 */
export const handleGetTags = createAsyncThunk(
  "tag/handleGetTags",
  async (
    { token, lang, page = 0, query = "", limit = 8, website = "" },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const { data } = await GetUrl(
        `tag?page=${page}&limit=${limit}&query=${query}&website=${website}`,
        {
          headers: {
            Authorization: token,
            "Accept-Language": lang,
          },
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      return rejectWithValue(error);
    }
  }
);

/**
 * 2) Récupérer un tag par ID
 *    GET /tag/:id
 */
export const handleGetTagById = createAsyncThunk(
  "tag/handleGetTagById",
  async ({ token, lang, id }, { rejectWithValue }) => {
    try {
      const { data } = await GetUrl(`tag/${id}`, {
        headers: { Authorization: token, "Accept-Language": lang },
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      return rejectWithValue(error);
    }
  }
);

/**
 * 3) Ajouter un tag
 *    POST /tag
 */
export const handleAddTag = createAsyncThunk(
  "tag/handleAddTag",
  async ({ token, lang, name, website }, { rejectWithValue }) => {
    try {
      const body = { name, website };

      const { data } = await PostUrl(`tag`, {
        data: body,
        headers: {
          Authorization: token,
          "Accept-Language": lang,
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      return rejectWithValue(error);
    }
  }
);

/**
 * 4) Mettre à jour un tag
 *    POST /tag/:id
 */
export const handleEditTag = createAsyncThunk(
  "tag/handleEditTag",
  async ({ token, lang, id, name, website }, { rejectWithValue }) => {
    try {
      const body = { name, website };

      const { data } = await PostUrl(`tag/${id}`, {
        data: body,
        headers: {
          Authorization: token,
          "Accept-Language": lang,
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      return rejectWithValue(error);
    }
  }
);

/**
 * 5) Supprimer un tag
 *    GET /tag/delete/:id
 */
export const handleDeleteTag = createAsyncThunk(
  "tag/handleDeleteTag",
  async ({ token, id }, { rejectWithValue }) => {
    try {
      const { data } = await GetUrl(`tag/delete/${id}`, {
        headers: {
          Authorization: token,
        },
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
      return rejectWithValue(error);
    }
  }
);

/*
  ========= État initial =========
*/
const initialState = {
  tagLoading: false,
  tags: [],
  removeLoading: false,
  addAndEditLoading: false,
  singleTag: null,
  totalTags: 0,
  currentPage: 1,
  tagsPerPage: 10,
  error: null,
};

/*
  ========= Slice principal =========
*/
const TagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    handleChangeSingleTag: (state, { payload }) => {
      state.singleTag = payload;
    },
  },
  extraReducers: (builder) => {
    /*
      handleGetTags
      ====================
    */
    builder
      .addCase(handleGetTags.pending, (state) => {
        state.tagLoading = true;
      })
      .addCase(handleGetTags.fulfilled, (state, { payload }) => {
        state.tagLoading = false;
        state.tags = payload?.tags || [];
        state.totalTags = payload?.count || 0; 
        state.error = null;
      })
      .addCase(handleGetTags.rejected, (state, { payload }) => {
        state.tagLoading = false;
        state.tags = [];
        state.error = payload ?? null;
      });

    /*
      handleGetTagById
      ====================
    */
    builder
      .addCase(handleGetTagById.pending, (state) => {
        state.tagLoading = true;
      })
      .addCase(handleGetTagById.fulfilled, (state, { payload }) => {
        state.tagLoading = false;
        state.singleTag = payload?.tag || null;
        state.error = null;
      })
      .addCase(handleGetTagById.rejected, (state, { payload }) => {
        state.tagLoading = false;
        state.singleTag = null;
        state.error = payload ?? null;
      });

    /*
      handleAddTag
      ====================
    */
    builder
      .addCase(handleAddTag.pending, (state) => {
        state.addAndEditLoading = true;
      })
      .addCase(handleAddTag.fulfilled, (state, { payload }) => {
        state.addAndEditLoading = false;
        if (payload?.tag) {
          state.tags = [payload.tag, ...state.tags];
        }
        state.error = null;
      })
      .addCase(handleAddTag.rejected, (state, { payload }) => {
        state.addAndEditLoading = false;
        state.error = payload ?? null;
      });

    /*
      handleEditTag
      ====================
    */
    builder
      .addCase(handleEditTag.pending, (state) => {
        state.addAndEditLoading = true;
      })
      .addCase(handleEditTag.fulfilled, (state, { payload, meta }) => {
        state.addAndEditLoading = false;
        if (payload?.tag) {
          state.tags = state.tags.map((t) =>
            t._id === meta.arg.id ? payload.tag : t
          );
        }
        state.error = null;
      })
      .addCase(handleEditTag.rejected, (state, { payload }) => {
        state.addAndEditLoading = false;
        state.error = payload ?? null;
      });

    /*
      handleDeleteTag
      ====================
    */
    builder
      .addCase(handleDeleteTag.pending, (state) => {
        state.removeLoading = true;
      })
      .addCase(handleDeleteTag.fulfilled, (state, { meta }) => {
        state.removeLoading = false;
        // On enlève le tag du tableau
        state.tags = state.tags.filter((tag) => tag._id !== meta.arg.id);
        state.error = null;
      })
      .addCase(handleDeleteTag.rejected, (state, { payload }) => {
        state.removeLoading = false;
        state.error = payload ?? null;
      });
  },
});

/*
  ========= Export =========
*/
export const { handleChangeSingleTag } = TagSlice.actions;
export default TagSlice.reducer;
